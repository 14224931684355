body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
    font-family: sans-serif;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #333;
}

.header-title {
    color: #fff;
    font-size: 1.5em;
    margin: 0;
}

.header-nav a {
    color: #fff;
    text-decoration: none;
    margin-left: 1em;
}

.header-nav a:hover {
    text-decoration: underline;
}

.home-page {
    padding: 2em;
    text-align: center;
}

.home-page h2 {
    font-size: 2em;
    margin-bottom: 1em;
}

.home-page p {
    font-size: 1.25em;
    line-height: 1.5;
}

.contact {
    padding: 2em;
}

.contact h2 {
    font-size: 2em;
    margin-bottom: 1em;
    text-align: center;
}

.contact p {
    font-size: 1.25em;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 1.5em;
}

.contact-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contact-list li {
    margin: 0.5em;
    font-size: 1.1em;
}

.contact-list a {
    color: #333;
    text-decoration: none;
}

.contact-list a:hover {
    text-decoration: underline;
}

.projects-page {
    padding: 2em;
    text-align: center;
}

.projects-page h2 {
    font-size: 2em;
    margin-bottom: 1em;
}

.projects-page ul {
    list-style-type: none;
    padding: 0;
}

.projects-page li {
    margin: 0.5em;
    font-size: 1.1em;
}

.projects-page a {
    color: #333;
    text-decoration: none;
}

.projects-page a:hover {
    text-decoration: underline;
}

