.projects-page {
    padding: 2em;
    text-align: center;
}

.projects-page h2 {
    font-size: 2em;
    margin-bottom: 1em;
}

.projects-page ul {
    list-style-type: none;
    padding: 0;
}

.projects-page li {
    margin: 0.5em;
    font-size: 1.1em;
}

.projects-page a {
    color: #333;
    text-decoration: none;
}

.projects-page a:hover {
    text-decoration: underline;
}
