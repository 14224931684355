.App {
    font-family: sans-serif;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-size: 16px;
}
