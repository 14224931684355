.contact {
    padding: 2em;
}

.contact h2 {
    font-size: 2em;
    margin-bottom: 1em;
    text-align: center;
}

.contact p {
    font-size: 1.25em;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 1.5em;
}

.contact-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contact-list li {
    margin: 0.5em;
    font-size: 1.1em;
}

.contact-list a {
    color: #333;
    text-decoration: none;
}

.contact-list a:hover {
    text-decoration: underline;
}
