.home-page {
    padding: 2em;
    text-align: center;
}

.home-page h2 {
    font-size: 2em;
    margin-bottom: 1em;
}

.home-page p {
    font-size: 1.25em;
    line-height: 1.5;
}
