.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #333;
}

.header-title {
    color: #fff;
    font-size: 1.5em;
    margin: 0;
}

.header-nav a {
    color: #fff;
    text-decoration: none;
    margin-left: 1em;
}

.header-nav a:hover {
    text-decoration: underline;
}
